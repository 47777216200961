import type {
    Exhibition, PreregistrationFormValues,
    UnmappedExhibition, UnmappedPreregistrationFormValues, UnmappedVisitor, Visitor,
} from "./types";
import {defaultPreregistrationFields} from "../../components/customizeRegistration/defaultFields";
import moment from "moment";
import {mapInterests} from "../interests/map";
import {API, EXHIBITION_IMAGE_RELATIVE_PATH} from "../../helpers/constants";

export const mapExhibition: Exhibition = (datum: UnmappedExhibition) => {
    if (!datum) return
    const preregistrationFrom = datum.StartDtPreregister ? moment(datum.StartDtPreregister, 'YYYY-MM-DD').toDate() : null
    const preregistrationTo = datum.EndDtPreregister ? moment(datum.EndDtPreregister, 'YYYY-MM-DD').toDate() : null
    const from = moment(datum.StartDt, 'YYYY-MM-DD').toDate()
    const to = moment(datum.EndDt, 'YYYY-MM-DD').toDate()
    return {
        image: {
            el: datum.Logo ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.Logo : null,
            en: datum.LogoEN ? API + EXHIBITION_IMAGE_RELATIVE_PATH + datum.LogoEN : null,
        },
        title: datum.Description ?? {
            en: datum.DescriptionEN ?? '',
            el: datum.DescriptionEL ?? '',
        },
        id: datum.id,
        dateFrom: from,
        dateTo: to,
        catalogueLanguage: Number(datum.CatalogueLanguage),
        preregistrationHasFieldGroups: datum.PreregistrationHasFieldGroups === "1",
        preregistrationRedirect: datum.PreregistrationRedirect,
        preregistrationHeaderImageLink: datum.PreregistrationHeaderImageLink ?? '',
        preregistrationEnabled: datum.isPreregistration === "1",
        preregistrationFrom: preregistrationFrom,
        preregistrationTo: preregistrationTo,
        preregistrationFormFields: datum.PreRegForm ? JSON.parse(datum.PreRegForm) : defaultPreregistrationFields,
        sendEmailAfterPreregistration: datum.PreregistrationEmail === "1",
        sendSMSAfterPreregistration: datum.PreregistrationSMS === "1",
        preregistrationCountry: datum.PreregistrationCountry,
        googleTagCode: datum.GoogleTagCode,
    }
}

export const mapVisitor: Visitor = (datum: UnmappedVisitor) => {
    if (!datum) return
    return {
        Badge: datum.Badge,
        Characterization: datum.Characterization,
        City: datum.address && datum.address.City ? datum.address.City : '',
        CompanyAddress: datum.address && datum.address.Address ? datum.address.Address : '',
        CompanyFirm: datum.CompanyFirm,
        CompanyName: datum.CompanyName,
        ContactId: datum.ContactId,
        CountryId: datum.CountryId,
        ExhibitionId: datum.ExhibitionId,
        InvitedBy: {
            id: datum.ExhibitorId,
            name: datum.ExhibitorName,
            totalInvitations: Number(datum.ExhibitorCount),
        },
        InvitationDt: moment(datum.InvitationDt).toDate(),
        PersonCount: datum.PersonCount,
        PostalCode: datum.address && datum.address.ZipCode ? datum.address.ZipCode : '',
        Status: datum.Status,
        StatusId: Number(datum.StatusId),
        VatNumber: datum.VatNumber,
        VisitorBusinessPhone: datum.VisitorBusinessPhone,
        VisitorEmail: datum.VisitorEmail,
        VisitorMobilePhone: datum.VisitorMobilePhone,
        VisitorName: datum.VisitorName,
        Website: datum.Website,
        Instagram: datum.Instagram,
        Facebook: datum.Facebook,
        // businessCategories: mapBusinessCategories(datum.businessCategories),
        existsInExhibition: datum.existsInExhibition,
        hasRevisited: datum.hasRevisited,
        id: datum.id,
        interests: mapInterests(datum.interests),
        isApproveRegistration: datum.isApproveRegistration,
        isIndividual: datum.isIndividual,
        organizerApprovalRejectReason: datum.organizerApprovalRejectReason
    }
}

export const mapVisitorDataToPreregistrationFormValues: PreregistrationFormValues = (datum: UnmappedPreregistrationFormValues) => {
    if (!datum) return
    return {
        characterization: datum.Characterization ?? '',
        city: datum.City ?? '',
        companyAddress: datum.Address ?? '',
        companyFirm: datum.CompanyFirm ?? '',
        companyName: datum.CompanyName ?? '',
        companyWebsite: datum.WebSite ?? '',
        instagram: datum.Instagram ?? '',
        facebook: datum.Facebook ?? '',
        countries: datum.CountryId,
        email: datum.VisitorEmail ?? '',
        existsInExhibition: datum.existsInExhibition === "1",
        hasRevisited: datum.hasRevisited === "1",
        id: datum.ContactId,
        interests: datum.Interests ? datum.Interests.split(',').filter( i => i !== '' ) : [],
        isIndividual: datum.isIndividual === "1",
        mobilePhone: datum.VisitorMobilePhone ?? '',
        name: datum.VisitorName ?? '',
        personCount: datum.PersonCount ?? 1,
        phone: datum.VisitorBusinessPhone ?? '',
        postalCode: datum.ZipCode ?? '',
        vatNumber: datum.VatNumber ?? '',
        otherInterests: datum.OtherInterests
    }
}
